@font-face {
  font-family: 'TiemposTextTest';
  src: url('/static/fonts/TiemposTextTest-Medium.woff2') format('woff2'),
    url('/static/fonts/TiemposTextTest-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/static/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/static/fonts/Montserrat-Medium.woff') format('woff');
  font-display: swap;
}